.select {
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #8ad6e4;
  margin: 3px;
  background: white;
}

.ant-select-selector {
  height: 100%;
  border: none !important;
  outline: none !important;
}

.ant-select-selection-search-input {
  border: none !important;
  background: red;
}
@media screen and (max-width: 1300px) {
  /* .ant-form-item-label{
	 height: 60px !important;
	 margin: 0;

   } */
}

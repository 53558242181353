@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.item {
  width: 300px;
  // border-radius: 5px;
  background: $base;
  position: relative;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  transition: all 0.3s ease-in-out;
  margin: 10px 0;
  padding-bottom: 15px;

  &:hover {
    scale: 1.02;
    -webkit-box-shadow: 5px 2px 10px 1px rgba(33, 51, 65, 0.2);
    -moz-box-shadow: 5px 2px 10px 1px rgba(33, 51, 65, 0.2);
    box-shadow: 5px 2px 10px 1px rgba(33, 51, 65, 0.2);
  }
}

.img_wrapper {
  width: 100%;
  height: 180px;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.img {
  // border-top-right-radius: 5px;
  // border-top-left-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  font-weight: 600;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  height: 60px;
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;

  overflow: hidden;
}

.description {
  color: gray !important;
  margin: 0px 15px;
  font-size: 12px;
  line-height: 1 !important;
  height: 80px;
  letter-spacing: 0.5px;
  overflow: hidden;
  background: $base;
  margin-bottom: 20px;
}

.btn {
  @include btn($gradient);
  margin-left: 45%;
  margin-bottom: 10px;
  height: 30px;
  background: $gold;
}

.header {
  position: absolute;
  top: 10px;
  left: 0;
}

.date {
  background: $gold;
  font-size: 10px;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 3px;
  left: -8px;
  width: 60px;
  height: 15px;
}

.location {
  font-size: 12px;
  margin: 15px;
  color: gray;
  letter-spacing: 0.5px;
}

.icon {
  font-size: 10px;
  margin-right: 3px;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.subheader {
  color: gray;
  margin: 10px auto;
  font-size: 14px;
}

.login {
  background: $light-silver;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h2 {
  @include h4();
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  //   flex-direction: column;
  font-size: 20px;
  margin-top: 120px;
}

.form {
  width: 400px;
  margin: 30px auto;
  -webkit-box-shadow: 0px 1px 8px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px -1px rgba(34, 60, 80, 0.2);
  border-radius: 20px;
  padding: 50px;
  background: white;
}

.forgetPassword {
  border: none;
  background: none;
  font-size: 12px;
  color: gray;
  transition: all 0.3s ease-in;

  &:hover {
    cursor: pointer;
  }
}

.row {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
}

.enter_btn {
  @include btn($gold);
  margin: 20px auto;
  border-radius: 20px;
  border: none;
  padding: 10px 40px;
  color: white;
  width: 60%;
}

.input {
  border: 0.5px solid $gold !important;
  background: white !important;
  outline: none !important;
  width: calc(100% - 10px);
  border-radius: 10px;
  padding-left: 10px;
}

.no_account {
  font-size: 11px;
  color: gray;
  text-align: center;
  margin-top: 20px;
}

.register_btn {
  border: none;
  background: none;
  color: $gold;
  font-size: 11px;
  &:hover {
    color: gray;
    cursor: pointer;
  }
}

.logo {
  height: 25px;
  width: 40px;
  margin-right: 10px;
  object-fit: contain;
}

.password_input {
  border: 0.5px solid $gold !important;
  background: white !important;
  outline: none !important;
  padding: 5px;
  width: calc(100% + 10px);
  border-radius: 10px;
  padding-left: 10px;
}

@import '../../styles/varibles.scss';
@import '../../styles/mixins.scss';

.main{
	width: 85%;
	margin: 50px auto;
	padding: 50px 0;
}

.h2{
	@include h2();
	text-align: center;
	margin: 30px 0 50px 0;

}
@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatIcon {
  width: 20px;
}

.table {
  width: 100%;
  margin: 0 auto;
}

.mobile_tender_table {
}

.more {
  color: $text-gold;
  font-size: 12px;
  transition: all 0.3s ease-in;
  &:hover {
    color: gray;
  }
}

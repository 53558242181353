@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.admin_account {
  width: 90%;
  margin: 120px auto;
}

.btns {
  width: 100%;
  display: flex;
  align-items: center;
}

.active_btn {
  @include btn($text-gold);
  border-radius: 20px;
  margin-right: 20px;
  height: 30px;
  padding: 5px 20px;
  cursor: pointer;
}

.btn {
  @include btn($silver);
  border-radius: 20px;
  margin-right: 20px;
  height: 30px;
  padding: 5px 20px;
  color: gray;
  cursor: pointer;
}

.header {
  display: grid;
  grid-template-columns: 3fr 0.7fr;
  align-items: center;
  justify-items: space-around;
  margin: 40px auto;
}

@media screen and (max-width: 900px) {
  .admin_account {
    width: 95%;
  }

  .header {
    width: 90%;
    grid-template-columns: 1fr;
    margin: 20px auto;
  }

  .h2 {
    font-size: 1.2em !important;
    margin-bottom: 20px !important;
  }

  .btn,
  .active_btn {
    font-size: 10px !important;
    height: 22px;
  }
}

.h2 {
  @include h2();
  display: flex;
  margin: 0;
  font-weight: 600;
  line-height: 1.4;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatIcon {
  width: 20px;
  height: 20px;
}

.more {
  color: $text-gold;
  font-size: 12px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    color: gray;
  }
}

.tableWrapper {
  display: flex;
  justify-content: center;
}

.icon {
  color: $text-gold;
}

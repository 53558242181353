.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatIcon {
  width: 20px;
}

.table {
  margin: 0 auto;
}

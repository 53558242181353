@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.reports {
  width: 80%;
  margin: 30px auto;
  margin-top: 100px;
}

@media screen and (max-width: 1100px) {
  .reports {
    width: 90%;
  }
}

.h2 {
  @include h2();
  margin: 30px auto;
}

.subtitle {
  letter-spacing: 0.5px;
  line-height: 1.8;
  color: gray;
}

.documents_item {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid $gold;
  width: 100%;
}

.documents_item:last-child {
  border-bottom: none !important;
}

.icon {
  color: $gold;
}

.documents {
  margin: 50px auto;
  margin-bottom: 100px;
}

.title {
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 500;
  font-size: 15px;
  width: 90%;
  text-align: left;
  border: none;
  background: none;

  // &:hover {
  //   font-weight: 600px;
  //   color: gray;
  //   cursor: pointer;
  // }
}

.content {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  @include btn($gold);
  height: 15px;
  width: 50px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 650px) {
  .h2 {
    line-height: 1.6;
    margin: 20px 0;
    font-size: 20px;
  }

  .reports {
    margin-top: 80px;
  }

  .documents_item {
    display: grid;
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 16px;
    width: 100%;
    margin: 0;
    font-weight: 500;
  }

  .subtitle {
    font-size: 14px;
    line-height: 1.8;
  }

  .btn {
    margin: 20px 0;
  }
}

.table {
  margin: 30px auto;
  margin-bottom: 80px;
}

.link {
  color: $text-gold;
  transition: all 0.3s ease-in;
  &:hover {
    cursor: pointer;
    color: gray;
  }
}

.table_item {
  display: flex;
}

.description_item {
  margin-bottom: 10px;
  line-height: 1.8;
  display: flex;
  flex-wrap: wrap;
}

.description_item_column {
  margin-bottom: 10px;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
}

.upload_btn {
  @include btn($text-gold);
  margin: 0 auto;
  height: 25px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.upload_wrapper {
}

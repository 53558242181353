@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.vacancies {
  min-height: 100vh;
}
.wrapper {
  width: 80%;
  margin: 30px auto;
  min-height: 50vh;
  margin-top: 150px;
}

.h2 {
  @include h2();
  margin: 30px 25px;
  margin-top: 80px;
}

.vacancy_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}

@media screen and (max-width: 1173px) and (min-width: 784px) {
  .vacancy_list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 783px) {
  .vacancy_list {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .h2 {
    font-size: 20px;
  }
}

.noVacancy {
  height: 50vh;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  text-align: center;
  margin: 0 auto !important;
}

@import '../../styles/varibles.scss';
@import '../../styles/mixins.scss';

.item{

	width: 360px;
	height: 230px;
	border-radius: 15px;
	background: $base;
	position: relative;
	-webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
-moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
transition: all 0.3s ease-in-out;
margin: 10px;
position: relative;
z-index: 0;

&:hover{
	scale: 1.01;
}

  

}

.img_wrapper{
	width: 100%;
	height: 100%;
border-radius: 15px;
}

.img{
	border-radius: 15px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}


.title{
	position: absolute;
	font-weight: 600;
	height: 35%;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 1px;
	top: 45px;
	left: 20px;
	color: white;
	text-shadow: 1px 1px 1px rgba(121, 129, 116, 0.75);
	z-index: 10;
	background: rgba(7, 7, 7, 0.324);
	padding: 20px 15px;
	width: 75%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 15px;
}

.description{
	color: gray;
	margin: 15px;
	font-size: 13px;
	line-height: 1.5;
	height: 50px;
	letter-spacing: 0.5px
}

.btn{
	@include btn($gradient);
	margin: 10px 15px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	padding: 8px 17px;
	font-size: 11px;
	
}

.header{
	position: absolute;
	top: 10px;
	left: 0;
}

.date{
	background: $gradient;
	font-size: 12px;
	color: white;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 15px;
	left: 0px;
	width: 60px;
}

.location{
	font-size: 12px;
	margin: 15px;
	color: gray;
	letter-spacing: 0.5px;
}

.icon{
	font-size: 10px;
	margin-right: 3px;

}
@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.team_content {
  width: 90%;
  margin: 80px auto;
  text-align: center;
  margin-top: 180px;
}

.h2 {
  @include h2();
  margin: 20px auto;
}

.subtitle {
  color: gray;
  line-height: 1.8;
  width: 85%;
  margin: 30px auto;
  letter-spacing: 0.5px;
}

.team_members {
  display: grid;
  gap: 3em;
  width: 90%;
  margin: 30px auto;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
}

.management {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  margin: 50px auto;
}

@media screen and (max-width: 500px) {
  .h2 {
    font-size: 20px;
  }

  .team_content {
    margin-top: 150px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@import "../../styles/mixins.scss";
@import "../../styles/varibles.scss";
.confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
}

.text {
  margin-top: 100px;
}

.green {
  color: rgb(40, 131, 79);
  margin-right: 10px;
  font-size: 20px;
}

.yellow {
  color: rgb(231, 176, 26);
  margin-right: 10px;
  font-size: 20px;
}

.error {
  display: flex;
  align-items: center;
}

.enter_btn {
  @include btn($gold);
  margin: 20px auto;
  border-radius: 20px;
  border: none;
  padding: 10px 40px;
  color: white;
  width: 60%;
  height: 15px;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.investmentPolicy {
  width: 90%;
  margin: 0 auto;
  margin-top: 110px;
  margin-bottom: 50px;
}

.title {
  @include h2();
  margin-bottom: 50px;
}

.subtitle {
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    color: $red;
  }
}

.wrapper {
  width: 90%;
  margin: 30px auto;
  margin-top: 80px !important;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px 50px;
  margin-bottom: 100px;
}

@media screen and (max-width: 750px) {
  .investmentPolicy {
    margin-top: 70px;
    margin-bottom: 30px;
  }

  .wrapper {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    margin-top: 0 !important;
  }
  .title {
    @include h2();
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 1.5;
    font-size: 20px;
  }
}

.content {
  background: rgb(244, 242, 242);
}

.chapter_title {
  font-size: 14px;
  color: gray;
  line-height: 1.5 !important;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    color: $red;
  }
}

.chap_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.5 !important;
  margin-bottom: 20px;
}

.chap_text {
  font-size: 15px;
}

.active {
  font-weight: 600;
  color: $red;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.text_item {
  margin-bottom: 15px;
  line-height: 2;
}

.documents_item {
  width: 80%;
  margin: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  .icon {
    margin-left: -5px !important;
    color: $gold;
    &:hover {
      cursor: pointer;
      color: gray;
    }
  }

  .title {
    margin: 0;
  }
}

.documents_btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 20px auto;
}

.btn_title {
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  margin: 10px auto;
  color: $gold;

  &:hover {
    font-weight: 600px;
    color: gray;
    cursor: pointer;
  }
}

.btn {
  @include btn($gold);
  height: 15px;
  width: 80px;
}

.downloadIcon {
}

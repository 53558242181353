@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  width: 80%;
  margin: 50px auto;
  margin-top: 130px;
}

.h2 {
  @include h3();
  margin: 50px auto;
  line-height: 1.5;
}

.salary {
  color: gray;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 20px 0;
}

.subtitle_block {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  color: gray;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.description {
  margin: 40px 0;
  line-height: 2;
  letter-spacing: 0.5px;
}

.subtitle {
  margin: 20px 0;

  letter-spacing: 0.5px;
}

.file {
  border: none;
  background: none;
  font-size: 18px;
  color: gray;
  transition: all 0.3s ease-in;
  letter-spacing: 0.5px;
  margin: 30px 0;

  &:hover {
    color: black;
  }
}

.icon {
  color: $light-blue;
}

.category {
  color: gray;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
}

.icon {
  color: $gold;
}

.documents {
  margin: 50px auto;
}

.title {
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  width: 90%;
  text-align: left;
  border: none;
  background: none;
  margin: 30px 0;
  color: gray;

  &:hover {
    font-weight: 600px;
    color: gray;
    cursor: pointer;
  }
}

.documents_item {
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  border-bottom: 1px solid $light-blue;
}

.documents_item:last-child {
  border-bottom: none !important;
}

.btn {
  @include btn($text-gold);
  height: 15px;
  width: 50px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 650px) {
  .h2 {
    font-size: 20px;
    line-height: 1.8;
    margin: 20px auto;
  }

  .description {
    font-size: 14px;
  }

  .documents_btns {
  }
}

.mobile_tender {
  width: 90%;
  margin: 0 auto;
}

.mobile_tender_table {
  margin: 30px auto;
}

.tender_item {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  width: 100%;
  margin: 3px 0;

  div {
  }
}

.item_title {
  color: gray;
}

.item_value {
  font-weight: 600;
  text-align: right;
}

.mobile_tag {
  font-size: 10px;
  margin: 0;
}

@media screen and (max-width: 932px) {
  .comming_soon {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    justify-content: center;

    div {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .comming_soon {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    margin-top: 0px;
    margin-bottom: 30px;
    justify-content: center;

    div {
    }
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.news_content {
  width: 85%;
  height: auto;
  margin: 50px auto;
  position: relative;
  margin-top: 150px;
}
.news_img_wrapper {
  position: relative;
  width: 75%;
  height: 480px;
  margin: 0 auto !important;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news_title {
  @include h3();
  font-weight: 600;
  line-height: 1.5;
}

.news_category {
  font-size: 12px;
  font-weight: 700;
  color: rgb(148, 147, 147);
  letter-spacing: 1px;
  margin-top: 30px;
}

.news_description {
  margin: 20px 0;
  padding: 10px 0;
  line-height: 2 !important;

  div {
    margin: 20px 0;
    line-height: 2 !important;
    letter-spacing: 0.5px;
  }
}

.news_date {
  font-size: 12px;
  letter-spacing: 0.5px;
  position: absolute;
  background: $gradient;
  padding: 0px 10px;
  color: white;
  top: 5px;
  left: -10px;
  background: $gold;
}

.line {
  @include separator();
  width: 100px;
}

@media screen and (max-width: 1000px) {
  .news_img_wrapper {
    width: 100%;
    height: 500px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1650px) {
  .news_img_wrapper {
    width: 100%;
    height: 700px;
  }
}

@media screen and (min-width: 1650px) and (max-width: 1850px) {
  .news_img_wrapper {
    width: 100%;
    height: 850px;
  }
}

@media screen and (min-width: 1850px) {
  .news_img_wrapper {
    width: 100%;
    height: 900px;
  }
}

@media screen and (max-width: 600px) {
  .news_img_wrapper {
    height: 350px;
  }

  .news_content {
    margin-top: 100px;
  }

  .news_title {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 20px;
  }

  .news_description {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  .news_date {
    top: 3px;
    font-size: 10px;
    padding: 0 5px;
  }

  .news_img_wrapper {
    height: 200px;
  }

  .news_category {
    font-size: 10px;
    margin-top: 20px;
  }
}

@import '../../styles/varibles.scss';
@import '../../styles/mixins.scss';



.h2{
	@include h2();
	margin: 30px auto;
	text-align: center;
}

.projects{
	width: 80%;
	margin: 30px auto;
	padding: 30px 0;
}

.content{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, auto));
	justify-content: center;
	gap: 3em;
	margin-top: 50px;
}
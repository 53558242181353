@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.banner {
  position: relative;
  margin: 0px 0;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}
.tender_banner {
  width: 100%;
  height: 100px;
  background: $gradient;
}

.banner_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  // opacity: 0.2;
}

.banner_title {
  position: absolute;
  top: 35px;
  left: 100px;
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(121, 129, 116, 0.75);
}

.introduction {
  padding: 20px;
  width: 90%;
  margin: 30px auto;
  line-height: 1.8;
  font-size: 16px;
  // 	-webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  // -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  // box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  // background: $light-silver;
}

.h2 {
  @include h2();
  margin: 50px auto;
  width: 90%;
  margin-top: 150px !important;
}

.divider {
  @include separator();
  margin: 20px 0;
}

.separator {
  height: 1px;
  width: 80%;
  margin: 0 auto;
  background: $gradient;
  -webkit-box-shadow: 0px 2px 10px -7px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 10px -7px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 10px -7px rgba(34, 60, 80, 0.2);
}

.tenders_list {
  margin: 30px auto;
  width: 90%;
}

.comming_soon {
  color: gray;
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    margin: 20px;
  }
}

.name {
  cursor: pointer;
  min-width: 250px !important;
  font-weight: 500;
  color: $dark-blue !important;

  &:hover {
    color: $gold !important;
  }
}

.tenders_table {
  margin-bottom: 80px;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .ant-table-cell {
    font-size: 12px !important;
    padding: 5px !important;
  }

  .tenders_list {
    margin: 30px auto;
    width: 95%;
  }
}

@media screen and (max-width: 931px) {
  .comming_soon {
    min-height: 40vh;
  }
}

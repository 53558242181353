$light-silver: #f6f8f7;
$silver: #f2f3ef;
$base: rgb(245, 246, 247);
$light-blue: #8ad6e4;
$dark-blue: rgb(21, 149, 174) 0%;
$light-green: #dcf09b;
$green: #80ed99;
$dark: #242d34;
// $red: rgba(239, 91, 91, 0.927);
// $gradient: #266a9ed1;
// $gradient: linear-gradient(
//   90deg,
//   rgba(8, 209, 249, 0.7861738445378151) 0%,
//   rgba(92, 215, 107, 1) 100%
// );

$text-color: rgb(232, 230, 230);
$gradient: #7f0f0f;
$red: #7f0f0f;
$gold: #d5aa49;
$gold2: #eccd857e;
$base2: #faf9f84f;
$text-gold: #d2b777;

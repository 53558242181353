@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.newsItem {
  width: 85%;
  height: 220px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 40px auto;
  position: relative;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  transition: all 0.3s ease-in;

  &:hover {
    scale: 1.01;
    -webkit-box-shadow: -2px 2px 10px 3px rgba(39, 60, 77, 0.2);
    -moz-box-shadow: -2px 2px 10px 3px rgba(39, 60, 77, 0.2);
    box-shadow: -2px 2px 10px 3px rgba(39, 60, 77, 0.2);
  }
}

.image_wrapper {
  width: 100%;
  height: 220px;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.text {
  padding: 20px 40px;
}

.title {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  height: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.5;
}

.description {
  color: gray;
  margin: 10px 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  height: 70px;
  overflow: hidden;
}

.btn {
  @include btn($gradient);
  height: 30px;
  margin-top: 5px;
  background: $gold;
}

.date {
  position: absolute;
  width: 100px;
  height: 25px;
  background: $gold;
  top: 3px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 11px;
  line-height: 1;
}

.btn_wrapper {
  display: flex;
  justify-content: flex-end;
}

.mobile_menu_modal {
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  background: $base;
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 14px !important;
    margin: 0;
  }

  .description {
    font-size: 12px !important;
    margin: 0 auto;
    height: 60px;
    overflow: hidden;
  }

  .btn {
    font-size: 12px !important;
    margin: 10px 0;
  }

  .newsItem,
  .image_wrapper {
    width: 90%;
    height: 200px;
  }
}

@media screen and (max-width: 650px) {
  .newsItem {
    grid-template-columns: 1fr;
    height: auto;
    border-radius: 15px;
    width: 80%;
    margin: 30px auto;
  }
  .image_wrapper {
    width: 100%;
    height: 150px;
  }

  .img,
  .image_wrapper {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .title,
  .description {
    margin: 10px auto;
    width: 100%;
    font-size: 12px !important;
  }

  .text {
    padding: 10px 20px;
  }

  .date {
    top: 2px;
    height: 20px;
    width: 85px;
  }
}

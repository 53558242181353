@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.wrapper {
  width: 95%;
  margin: 50px auto;
}

.h2 {
  @include h2();
  text-align: left;
  margin: 20px auto;
  width: 95%;

  // border-top: 1px solid $gold;

  margin-top: 100px;
  text-align: center;
}

.subheader {
  text-align: center;
  color: gray;
  margin-bottom: 50px;
  letter-spacing: 1px;
  font-size: 14px;
}

.carousel_wrapper {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .h2 {
    margin: 20px auto;
  }
}

.titleIcon {
  margin-right: 5px;
  // color: $gold;
  font-size: 22px;
}

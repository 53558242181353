@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  min-height: 70vh;
  color: gray;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  width: 80%;
}

.h2 {
  @include h2();
  margin: 50px auto;
  width: 100%;
  margin-top: 80px;
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
}

.documents_btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  margin-top: 100px !important;
}

.title {
  display: block !important;
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  margin: 10px auto;

  color: $text-gold;

  &:hover {
    font-weight: 600px;
    color: gray;
    cursor: pointer;
  }
}

.site {
  color: #69b1ff;

  &:hover {
    color: $gold;
  }
}

.contactsItem {
  display: grid;
  grid-template-columns: 80px 1fr;
}

.contactTitle {
  color: gray;
  font-size: 13px;
}

@media screen and (max-width: 800px) {
  .documents_btns {
    width: 90%;
  }
  .content {
    width: 95%;
    margin: 0 auto;
  }

  .h2 {
    font-size: 16px;
    width: 90%;
    margin-top: 50px;
  }

  .documents_btns a {
    font-size: 12px !important;
  }
}

.documents_item {
  width: 100% !important;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.icon {
  color: $gold !important;
  &:hover {
    cursor: pointer;
    color: gray;
  }

  .title {
    margin: 0;
  }
}

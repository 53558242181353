@import '../../styles/varibles.scss';
@import '../../styles/mixins.scss';
.projectItem{
	width: 300px;
	height: 350px;
	border: 5px solid white;
	-webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	border-radius: 15px;
	position: relative;
	transition: all 0.3s ease-in;

	&:hover{
		scale: 1.03;
	}
}

.img_wrapper{
	width: 300px;
	height: 200px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.category{
	position: absolute;
	top: 10px;
	right: -15px;
	background: $gradient;
	font-size: 12px;
	color: white;
	padding: 5px 10px;
	letter-spacing: 0.5px;
}

.title{
	margin: 15px 10px;
	font-size: 14px;
	letter-spacing: 0.5px;
	font-weight: 600;
	min-height: 35px;
}

.date{
	

	letter-spacing: 0.5px;
}

.subtitle{
	font-size: 12px;
	color: gray;
	padding: 0 10px;
	overflow: hidden;
	margin-top: 15px;
}

.header{
	font-size: 11px;
	display: flex;
	justify-content: space-between;
    padding: 0 10px;
	color: rgb(24, 173, 227);
	
	div{
		letter-spacing: 0.5px;
	}


}

.text{
	padding: 5px ;
}
@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  color: gray;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  width: 80%;
}

.h2 {
  @include h2();
  margin: 50px auto;
  width: 100%;
  margin-top: 50px;
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
}

.documents_btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  margin-top: 100px;
}

.title {
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  margin: 10px auto;
  color: $text-gold;

  &:hover {
    font-weight: 600px;
    color: gray;
    cursor: pointer;
  }
}

.contactsItem {
  display: grid;
  grid-template-columns: 80px 1fr;
}

.contactTitle {
  color: gray;
  font-size: 13px;
}

.site {
  color: #69b1ff;

  &:hover {
    color: $gold;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 95%;
  }

  .h2 {
    font-size: 18px;

    width: 90%;
    margin: 30px auto;
    margin-top: 120px;
  }
  .contactsItem {
    display: grid;
    grid-template-columns: 45px 1fr;
  }
}

.documents_item {
  width: 100%;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  .icon {
    margin-left: -5px !important;
    color: $gold;
    &:hover {
      cursor: pointer;
      color: gray;
    }
  }

  .title {
    margin: 0;
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.news {
  width: 80%;
  margin: 20px auto;
  min-height: 300px;
  margin-top: 150px;
}

.header {
  @include h2();
  margin: 30px 25px;
  margin-top: 80px;
}

.divider {
  @include separator();
}

@media screen and (max-width: 1000px) {
  .header {
    width: 85%;
    margin: 60px auto;
  }
  .news {
    width: 100%;
    margin: 120px auto;
  }
}

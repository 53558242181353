@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.tender {
  width: 80%;
  margin: 30px auto;
  padding-bottom: 50px;
  margin-top: 100px;
}

.title {
  @include h3();
  font-weight: 600;
  line-height: 1.3;
}

.dates {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: gray;
  letter-spacing: 0.5px;
  margin: 30px 0;
}

.number {
  // color: gray;
  font-size: 14px;
  margin: 15px 0;
}

.category {
  font-size: 14px;
  margin-top: 30px;
  color: gray;
}

.tender_details {
  line-height: 1.8;
}

.files {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
}

.file {
  padding: 10px;
  font-size: 15px;
  border: none;
  background: none;
  color: gray;
  letter-spacing: 0.5px;
  line-height: 0.8;
  transition: all 0.3s ease-in;
  font-weight: 600;
  cursor: pointer;
  color: $gold !important;
  line-height: 1.5;
  text-align: left;

  &:hover {
    color: $red !important;
  }
}

.btn {
  @include btn($gold);
  width: 100px;
  font-size: 14px;
  margin: 30px 0;
  height: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  letter-spacing: 0.5px;
}

.back {
  font-size: 12px;
  display: block;
  margin: 40px 100px !important;
  color: $dark-blue;
  &:hover {
    color: $gold;
  }
}
.backIcon {
  color: $gold;
}

@media screen and (max-width: 650px) {
  .dates {
    display: grid;
    grid-template-columns: 1fr;
  }

  .tender {
    margin-top: 50px;
  }

  .tender_details {
    width: 100% !important;
  }

  figure {
    width: 100%;
    margin: 0;
  }
}

.tender_details {
  margin-bottom: 40px;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.subtitle {
  margin: 20px auto;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: gray;
  text-align: left;
  width: 90%;
  padding-left: 20px;
}

.h2 {
  color: rgb(46, 46, 46);
  letter-spacing: 1px;
  margin: 20px 0;
  text-align: center;
}

.section {
  background: $base;
  width: 95%;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 0;
}

.form {
  width: 95%;
  margin: 0 auto;
  padding: 0 !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  @include btn($gold);
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: $gold;
  border: 1px solid $gold;
  border-radius: none !important;
  &:hover {
    color: $gold !important;
    border: 1px solid $gold !important;
  }
}

.btn2 {
  @include btn($gold);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: none;
  &:hover {
    background: $gold !important;
    color: whitesmoke !important;
  }
}

.btns {
  width: 93%;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_input {
  border: 1px solid white;
  border-radius: none !important;
  border: 0.5px solid $gold;
  padding: 5px 10px;
  display: flex;
  &:hover {
    border: 0.5px solid $gold;
    -webkit-box-shadow: 0px 1px 1px 0px #d1c4a86d;
    -moz-box-shadow: 0px 1px 1px 0px #d1c4a86d;
    box-shadow: 0px 1px 1px 0px #d1c4a86d;
  }
  &:active,
  &:focus,
  &:focus-within,
  &:target,
  &:visited {
    outline: none !important;
    border: 0.5px solid $gold;
    -webkit-box-shadow: 0px 1px 1px 0px #d1c4a86d;
    -moz-box-shadow: 0px 1px 1px 0px #d1c4a86d;
    box-shadow: 0px 1px 1px 0px #d1c4a86d;
  }
}

.form_input div {
  display: flex;
}

.textarea {
  min-height: 100px !important;
  @extend .form_input;
}

.icon {
  font-weight: 900 !important;
  color: $gold;
  font-size: 16px;
}

@media screen and (max-width: 650px) {
  .form {
    width: 100%;
  }
  .section {
    width: 90%;
    margin: 15px auto;
  }
}

.infoIcon {
  color: $red;
  font-size: 14px;
  margin-left: 5px;
  font-weight: 400;
  &:hover {
    cursor: pointer;
  }
}

.tooltip_text_icon {
  margin-right: 5px;
  color: $gold;
  font-size: 14px;
  text-align: center;
}

.tooltip {
  background: rgb(249, 248, 248);
  color: rgb(83, 82, 82);
  padding: 5px 0;
  width: auto;
}

.formItem div {
  display: flex;
}

.intro {
  color: gray;
  width: 90%;
  margin: 20px auto;
}

.tooltipContent {
  color: rgb(6, 6, 6);
  font-weight: 500;
  padding: 0 3px;
}

.tooltipTitle {
  color: rgb(83, 82, 82) !important;
  font-weight: 400;
}

.tooltip_row {
  display: flex;
}

.table {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .table {
    font-size: 10px;
  }
}

th {
  text-align: left;
}

.loader {
  position: fixed !important;
  top: 50% !important;
  left: 54.5% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 9999 !important;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.myAccount {
  background: $base;
}

.wrapper {
  width: 95%;
  display: grid;
  grid-template-columns: 300px 3.5fr;
  padding-top: 100px;
  min-height: calc(100vh - 100px);
  gap: 20px;
  justify-content: center;
  margin: 0px auto;
}

.personalInfo {
  background: white;
  border-radius: 20px;
  width: 100%;
  margin: 20px auto;
}

.content {
  background: white;
  border-radius: 20px;
  width: 93%;
  margin: 20px auto;
  padding: 10px 40px;
}

@media screen and (max-width: 1020px) {
  .wrapper {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }

  .personalInfo {
    background: white;
    border-radius: 20px;
    height: auto;
    font-size: 12px;
    width: 93% !important;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: flex-start !important;
  }

  .content {
    width: 85%;
    margin: 0 auto;
  }

  .myAccount {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
    width: 100vw !important;
  }
}

@media screen and (max-width: 800px) {
  .content {
    width: 85%;
    padding: 0 20px;
  }
}

.img_wrapper {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  background: rgb(243, 241, 241);
  border-radius: 50px;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: contain;
  }
}

.content_item {
  font-size: 12px;
  margin: 10px auto;
  background: repeating-radial-gradient();
  align-items: center;
  width: 85%;
  display: grid;
  grid-template-columns: 1.1fr 2.5fr;
}

.content_item_title {
  color: gray;
  display: flex;
  height: 100%;
  align-items: flex-start;
  font-size: 11px;
}

.content_item_value {
  display: flex;
  height: 100%;
  align-items: flex-start;
  line-height: 1.5;
}
.chatIcon {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  &:hover {
    color: gray !important;
    cursor: pointer;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_icon {
  color: whitesmoke;
  margin-right: 5px;
}

.image {
  display: flex;
  flex-direction: column;

  position: relative;
}

.btn {
  @include btn($text-gold);
  width: 70%;
  margin: 30px auto;
  border-radius: 20px;
  height: 30px;
  padding: 0px 10px;
}

.more {
  color: $text-gold;
  font-size: 12px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    color: gray;
  }
}

.intro {
  width: 100%;
  margin: 20px auto;
  font-size: 14px;
  color: gray;
}

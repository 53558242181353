@import "../../styles/varibles.scss";

.header {
  height: 450px;
  width: 100%;
  background: $red;
}

.content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  align-items: flex-end;
}

.logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: end;
  position: relative;
}

.logoImg {
  width: 60%;
  height: 70%;
  position: absolute;
  bottom: 0;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 370px;
}

.title {
  color: $text-color;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 3px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  line-height: 0;

  div {
    margin: 0;
  }
}

.subtitle {
  color: $text-color;
  width: 80%;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 500;
  color: $text-gold;
}

@media screen and (max-width: 1190px) and (min-width: 916px) {
  .title {
    font-size: 26px;
  }
  .subtitle {
    font-size: 16px;
  }

  .logoImg {
    width: 65%;
    height: 68%;
  }

  .header {
    height: 400px;
  }
}

@media screen and (max-width: 915px) and (min-width: 712px) {
  .title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 14px;
  }
  .header {
    height: 350px;
  }

  .text {
    height: 270px;
  }

  .logo {
    width: 100%;
  }

  .logoImg {
    width: 70%;
    height: 65%;
  }
}

@media screen and (max-width: 711px) and (min-width: 580px) {
  .header {
    height: 300px;
  }

  .text {
    height: 230px;
  }

  .title {
    font-size: 16px;
  }
  .subtitle {
    font-size: 12px;
  }

  .logo {
    width: 100%;
  }

  .logoImg {
    width: 60%;
    height: 60%;
  }
}

@media screen and (max-width: 580px) and (min-width: 500px) {
  .header {
    height: 270px;
  }

  .text {
    height: 200px;
  }

  .title {
    font-size: 14px;

    div {
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
  }
  .subtitle {
    font-size: 11px;
  }

  .logo {
    width: 100%;
  }

  .logoImg {
    width: 66%;
    height: 60%;
  }
}

@media screen and (max-width: 500px) {
  .header {
    height: 270px;
  }

  .text {
    height: 200px;
  }

  .title {
    font-size: 14px;

    div {
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
  }
  .subtitle {
    font-size: 11px;
  }

  .logo {
    width: 100%;
  }

  .logoImg {
    width: 75%;
    height: 60%;
  }
}

@media screen and (max-width: 500px) {
  .header {
    height: 270px;
  }

  .text {
    height: 200px;
  }

  .title {
    font-size: 14px;

    div {
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
  }
  .subtitle {
    font-size: 11px;
    line-height: 1.5;
  }

  .logo {
    width: 100%;
  }

  .logoImg {
    width: 75%;
    height: 58%;
  }
}

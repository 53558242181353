@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.managementItem {
  text-align: center;
  margin: 20px;
  -webkit-box-shadow: -2px 2px 10px 3px rgba(39, 60, 77, 0.2);
  -moz-box-shadow: -2px 2px 10px 3px rgba(39, 60, 77, 0.2);
  box-shadow: -2px 2px 10px 3px rgba(39, 60, 77, 0.2);
  padding: 20px;
  border-radius: 15px;
  // background: $base;
  transition: all 0.3s ease-in;
  &:hover {
    scale: 1.03;
  }
}

.name {
  font-weight: 600;
  margin: 10px 0;
  font-size: 16px;
}

.position {
  font-size: 12px;
  color: gray;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.line {
  width: 100px;
  height: 1px;
  background: $gold;
  margin: 10px auto;
}

.id_icon {
  color: $gold;
  font-size: 14px;
}

.icon {
  width: 50px;
  height: 45px;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  min-height: 50vh;
  width: 80%;
  margin: 30px auto;
}

.h2 {
  @include h2();
  margin-top: 120px;
}

.documents_item {
  padding: 25px 0;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 200px;
  justify-content: space-between;
  align-items: center;

  border-bottom: 0.5px solid $gold;
}

.documents_item:last-child {
  border-bottom: none !important;
}

.icon {
  color: $gold;
}

.documents {
  margin: 50px auto;
}

.title {
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  width: 90%;
  text-align: left;
  border: none;
  background: none;

  &:hover {
    font-weight: 600px;
    color: gray;
    cursor: pointer;
  }
}

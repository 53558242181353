@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.mobileHeader {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  -webkit-box-shadow: 2px 4px 8px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 4px 8px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 4px 8px -1px rgba(34, 60, 80, 0.2);
  background: $red;
  z-index: 100;
}

.header_logo {
  width: 130px;
  height: 40px;
  margin-top: 10px;
  margin-left: 30px;
}

.mobile_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 30px;
}

.mobile_menu div:first-child {
  width: 35px;
  margin-bottom: 7px;
  border-bottom: 2px solid $text-gold;
}

.mobile_menu div:last-child {
  width: 20px;
  margin-bottom: 7px;
  border-bottom: 2px solid $text-gold;
}

@media screen and (max-width: 597px) and (min-width: 397px) {
  .mobileHeader {
    grid-template-columns: 2fr 1fr;
  }

  .logo {
    display: flex;
    align-items: center;
  }
  .logo_text {
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 396px) {
  .mobileHeader {
    grid-template-columns: 2fr 1fr;
  }

  .logo {
    display: flex;
    align-items: center;
  }
  .logo_text {
    font-size: 7px;
    line-height: 1.6;
  }
}

.mobile_menu_item {
  display: flex;
  margin: 10px;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-in;

  &:hover {
    color: $light-blue;
  }
}

.icon {
  margin-right: 10px;
  color: $gold;
}

.mobile_menu_item:hover {
  color: $text-gold;
}

.modal_header {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $text-gold;
  padding: 0;
  height: 50px;

  ol {
    padding-left: 10px;
    padding-right: 30px;
  }
}

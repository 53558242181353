@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.footer {
  -webkit-box-shadow: 0px -1px 5px 1px rgba(39, 60, 77, 0.2);
  -moz-box-shadow: 0px -1px 5px 1px rgba(39, 60, 77, 0.2);
  box-shadow: 0px -1px 5px 1px rgba(39, 60, 77, 0.2);
  width: 100%;
  height: 300px;
  background: $red;
}

.wrapper {
  height: 100%;
  background: $red;
  color: whitesmoke;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 2;
  // background: rgb(228, 228, 229);
  // color: whitesmoke;
}

.logo {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.8;
  letter-spacing: 0.5px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  // border-right: 2px solid $green;
}

.icon {
  margin-top: 15px;
  margin-right: 15px;
  font-size: 17px;
  color: whitesmoke;
  transition: all 0.3s ease-in;
  font-weight: 600;

  &:hover {
    scale: 1.3;
    color: $gold;
  }
}

.item {
  padding: 15px 50px;
}

.contacts {
  padding-top: 20px;
  div {
    margin: 5px 0;
    font-size: 13px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in;
    font-weight: 600;

    &:hover {
      color: $text-gold;
    }
  }
}

.title {
  width: 100%;
  margin-top: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 10px;
  background: $gold;
  width: 100%;
  color: white;
}

.line {
  height: 2px;
  width: 50px;
  background: $red;
  margin-top: 10px;
}

.logo_img {
  width: 110px;
  height: 50px;
  margin-right: 25px;
}
.logo_img_mobile {
  width: 60px;
  height: 30px;
  margin-right: 25px;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.social_networks {
  width: 80%;
  margin: 5px auto;
}

@media screen and (max-width: 766px) {
  .wrapper {
    height: auto;
    grid-template-columns: 1fr;
  }

  .logo {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .logo_wrapper {
    margin-top: 30px;
    width: 100%;
  }

  .social_networks {
    margin: 0 !important;
    line-height: 1.5;
  }

  .contacts {
    padding-top: 10px;
    line-height: 1.5 !important;
    div {
      font-weight: 400;
      line-height: 1.5 !important;
      margin-top: 0;
      &:hover {
        scale: 2;
      }
    }
  }
}

.logo_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer_logo {
  width: 210px;
  height: 70px;
}

.icon {
  color: $text-gold;
}

.contacts_icon {
  // color: $text-gold;
}

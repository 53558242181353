@mixin btn($color) {
  background: $color;
  padding: 10px 30px;
  border: none;
  color: white;
  letter-spacing: 1px;
  height: 35px;
  font-size: 12px;
  transition: all 0.3s ease-in;
  -webkit-box-shadow: 2px 4px 8px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 4px 8px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 4px 8px -1px rgba(34, 60, 80, 0.2);
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    scale: 1.03;
    //   background: $light-blue;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  }
}

@mixin h1() {
  font-size: 32px;
  color: $dark;
  letter-spacing: 2px;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.28);
  line-height: 0.8;
  margin: 50px 0 20px 0;
}

@mixin h2() {
  font-size: 26px;
  //   color: rgb(73, 72, 72);
  color: rgb(93, 87, 87);
  letter-spacing: 2px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.28);
  line-height: 0.8;
  margin: 30px 0 0px 0;
}

@mixin h3() {
  font-size: 23px;
  color: rgb(73, 72, 72);
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px rgba(63, 63, 63, 0.28);
  line-height: 0.8;
  margin: 30px 0 20px 0;
}

@mixin h4() {
  margin: 20px 10px;
  letter-spacing: 1px;
  font-weight: 600;
}

@mixin separator() {
  border-bottom: 3px solid $red;
  width: 70px;
}

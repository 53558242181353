@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.img_wrapper {
  width: 100%;
  height: 200px;
}

.about_content {
  padding-top: 40px;
  margin-top: 80px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  @include h2();
  margin: 50px auto;
  text-align: center;
}

.text {
  width: 90%;
  margin: 50px auto;
  div {
    margin: 15px 0;
    line-height: 2;
    letter-spacing: 0.5px;
    font-size: 15px;
  }
}

.mission {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4em;
}

.mission_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.h3 {
  @include h2();
  font-size: 22px;
  line-height: 1.5;
}

.icon {
  color: $gold;
  font-size: 17px;
  margin: 10px 25px;
}

.text_item {
  font-size: 14px !important;
  display: flex;
  letter-spacing: 0.5px !important;
  // color: rgb(77, 77, 77);
  align-items: center;
  justify-content: flex-start;
  min-height: 20px;
  height: 20px;
  max-height: 70px;
  padding: 0;
  margin: 0;
}

.introduction {
  color: gray;
  font-size: 15px !important;
  margin: 35px 0px !important;
}

@media screen and (max-width: 982px) {
  .mission {
    width: 90%;
    margin: 0 auto !important;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .description {
    width: 90%;
    margin: 20px auto !important;
    font-size: 14px !important;
  }

  .title {
    font-size: 20px;
  }

  .about_content {
    margin-top: 40px;
  }

  .h3 {
    font-size: 16px;
    margin: 0;
  }

  .text_item {
    font-size: 12px !important;

    display: flex;
    letter-spacing: 0.5px !important;
    color: rgb(77, 77, 77);
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
    height: 50px;
    max-height: 70px;
    padding: 0;
    margin: 10px;
  }
}

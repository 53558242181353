@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";
.login {
  background: $light-silver;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h2 {
  @include h4();
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  //   flex-direction: column;
  font-size: 20px;
  margin-top: 130px;
}

.form {
  width: 400px;
  margin: 30px auto;
  -webkit-box-shadow: 0px 1px 8px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px -1px rgba(34, 60, 80, 0.2);
  // border-radius: 20px;
  padding: 30px 50px;
  background: white;
  border-radius: 20px;
}

.forgetPassword {
  border: none;
  background: none;
  font-size: 12px;
  color: gray;
  transition: all 0.3s ease-in;
  position: relative;
  z-index: 20;

  &:hover {
    color: $red !important;
    cursor: pointer;
  }
}

.row {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
}

.enter_btn {
  @include btn($gold);
  margin: 30px auto;
  border: none;
  padding: 10px 50px;
  color: white;
  width: 65%;
  font-size: 12px !important;
  margin-top: 40px;
  border-radius: 20px;
}

.input {
  border: 0.5px solid $gold !important;
  background: none !important;
  outline: none !important;
  padding: 2px 8px;
  width: calc(100% - 10px);
  // border-radius: 5px;
  padding-left: 10px;
  font-size: 12px;
  border-radius: 5px;
}

.input.internal-autofill-selected,
.password_input.internal-autofill-selected {
  background: none !important;
}

.no_account {
  font-size: 11px;
  color: gray;
  text-align: center;
  margin-top: 20px;
}

.register_btn {
  border: none;
  background: none;
  color: $red;
  font-size: 11px;

  &:hover {
    color: gray;
    cursor: pointer;
  }
}

.logo {
  height: 25px;
  width: 40px;
  margin-right: 10px;
  object-fit: contain;
}

.password_input {
  border: 0.5px solid $gold !important;
  background: white !important;
  outline: none !important;
  padding: 5px;
  width: calc(100% + 10px);
  border-radius: 0;
  padding-left: 10px;
  border-radius: 5px;
}

.message {
  width: 80%;
  margin: 10px auto !important;
  color: gray;
}

.seconds {
  font-weight: 800;
  color: black;
  font-size: 16px;
}

.formItem {
  margin: 5px 20px;
}

.ant-form-item .ant-form-item-label > label {
  font-size: 12px !important;
}

@media screen and (max-width: 450px) {
  .form {
    width: 300px;
    padding: 30px 15px;
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.feedback {
  width: 100%;
  // margin-top: 30px;
  // background: $base;
  padding: 30px 0;
  // -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  // -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  // box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.wrapper {
  padding: 50px;
}

.form {
  gap: 1em;
  margin-top: 40px;
}

.input {
  padding: 10px;
  border-radius: 15px;
  border: 0.5px solid $gold;
  transition: all 0.3s ease-in;
  outline: none;

  &:hover {
    border: 0.5px solid $gold;
    // -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
    // -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 2px 2px 1px rgba(142, 133, 101, 0.2);
  }
}

.select {
  border-radius: 15px;
  border: 1px solid $light-blue;
  transition: all 0.3s ease-in;
  outline: none;
}

.title {
  @include h2();
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.5;
}

.subtitle {
  color: gray;
  text-align: center;
  margin: 30px auto;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.btn {
  @include btn($gradient);
  margin: 10px auto;
  width: 150px;
  background: $gold;
}

.ant-select-selector {
  border: none !important;
  height: 50px !important;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 20px;
    margin: 10px auto;
  }

  .subtitle {
    line-height: 1.6;
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.team_item {
  display: grid;
  width: 100%;
  width: 260px;
  border-radius: 15px;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  line-height: 1.5;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
  background: white;

  &:hover {
    scale: 1.02;
  }
}

.img_wrapper {
  width: 280px -10px;
  height: 250px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0;
  border: 6px solid white;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.03);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.03);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.03);
  border-radius: 15px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // border-top-left-radius: 15px;
  // border-top-right-radius: 15px;
  border-radius: 15px;
}

.team_text {
  padding: 20px;
  text-align: center;
  margin: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  // background: rgb(248, 246, 246);
}

.name {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  width: 90%;
  margin: 10px auto;
}

.position {
  color: gray;
  font-size: 12px;
  margin-top: 10px;
  letter-spacing: 0.5px;
}

.icon {
  color: $gold;
}

.description {
  font-size: 13px;
}

// .divider{
// 	width: 100%;
// 	height: 1px;
// 	background: $gradient;
// 	padding: 0 auto;
// 	margin: 15px auto;

// }

.cv {
  font-size: 12px;
  text-align: center;
  color: $gold;
  margin: 10px 0;
  letter-spacing: 1px;

  &:hover {
    color: gray;
    cursor: pointer;
    .icon {
      color: gray;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

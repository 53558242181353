@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.item {
  width: 85%;
  height: 180px;
  background: $base;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  transition: all 0.3s ease-in;
  border: 2px solid white;

  &:hover {
    scale: 1.03;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1335px) {
  .title {
    font-size: 15px;
    margin: 10px 0 !important;
    height: 30px !important;
  }

  .divider {
    margin: 20px 0 !important;
  }

  .description {
    font-size: 13px !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 1334px) and (min-width: 1173px) {
  .title {
    font-size: 13px;
    margin: 10px 0 !important;
    height: 30px !important;
  }

  .divider {
    margin: 20px 0 !important;
  }

  .description {
    font-size: 12px !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 1172px) {
  .title {
    font-size: 13px;
    margin: 10px 0 !important;
    height: 30px !important;
  }

  .divider {
    margin: 20px 0 !important;
  }

  .description {
    font-size: 12px !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 783px) {
  .item {
    margin: 0 auto;
    height: auto !important;
  }

  .title {
    font-size: 12px;
    margin: 10px 0 !important;
    height: 30px !important;
  }

  .description {
    font-size: 10px !important;
    margin-top: 20px !important;
  }
}

.title {
  font-weight: 500;
  margin: 20px 0;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-size: 14px;
  height: auto !important;
}

.salary {
  color: gray;
  font-size: 12px;
  margin: 10px 0;
  letter-spacing: 0.5px;
}

.divider {
  height: 1px;
  width: 100%;
  background: $gold;
  margin: 20px 0;
}

.subtitle_block {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 12px;
  color: gray;
}

.description {
  line-height: 1.8;
  font-size: 13px;
  color: gray;
}

.date {
  font-size: 10px;
  color: gray;
  margin-top: 25px;

  text-align: right;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.slider {
  width: 100%;
  height: 85vh;
  margin: 0 auto;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.background {
  height: 100%;
  width: 100%;
}

.slide {
  height: 85vh;
  display: grid;
}

.content {
  display: grid;
  grid-template-columns: 0.9fr 2fr;
  grid-template-areas:
    "desc img"
    "desc img";
}

.img_wrapper {
  width: 100%;
  height: 100%;
  grid-area: img;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  width: 100%;
  height: 85vh;
  grid-area: desc;
  display: grid;
  align-content: center;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.text_wrapper {
  width: 100%;
  height: auto;
  padding: 0 30px;
}

.border {
  height: 2px;
  width: 60px;
  background: $green;
  margin-left: 20px;
  margin-top: -10px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
  line-height: 1.8;
  text-align: left;
  letter-spacing: 1px;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.28);
}

.subtitle {
  margin: 14px;
  font-size: 14px;
  text-align: left;
  line-height: 1.8;
  letter-spacing: 0.5px;
}

.btn {
  @include btn($gradient);
  &:hover {
    cursor: pointer;
  }
  background: $gold;
}

.btns_wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;
}

@media screen and (max-width: 2500px) and (min-width: 1500px) {
  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 2;
  }
}

@media screen and (max-width: 1500px) {
  .title {
    font-size: 15px;
  }

  .subtitle {
    font-size: 13px;
  }
}

@media screen and (max-width: 1150px) {
  .slider,
  .slide {
    width: 100%;
  }
  .slide {
    height: auto;
    max-height: 90vh;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-areas:
      "img img"
      "desc desc";
  }

  .text {
    align-content: flex-start;
    padding: 30px;
  }
}

@media screen and (max-width: 1150px) and (min-width: 950px) {
  .slider,
  .slide {
    width: 100%;
    height: auto;
  }
  .img_wrapper {
    width: 100%;
  }

  .text {
    height: auto;
    box-shadow: none;
  }

  .text_wrapper {
    height: auto;
  }

  .img {
    object-fit: contain;
    height: 100%;
  }
  .title {
    font-size: 15px;
    margin: 10px;
  }

  .subtitle {
    font-size: 14px;
  }
  .btn {
    font-size: 10px;
    height: 25px;
    padding: 8px 15px;
  }

  .border {
    margin-left: 10px;
    margin-top: -5px;
  }
}

@media screen and (max-width: 950px) and (min-width: 615px) {
  .slider,
  .slide {
    width: 100%;
    height: auto;
  }

  .text {
    height: auto;
    box-shadow: none;
  }

  .img_wrapper {
    height: 35vh;
  }
  .img {
    object-fit: contain;
  }
  .title {
    font-size: 12px;
    margin: 10px 15px;
  }
  .subtitle {
    font-size: 10px;
  }

  .border {
    display: none;
  }
  .btn {
    font-size: 10px;
    height: 25px;
    padding: 8px 12px;
  }
}

@media screen and (max-width: 615px) {
  .slide,
  .slider {
    height: auto;
    max-height: 75vh;
  }

  .img_wrapper {
    height: 33vh;
  }
  .title {
    font-size: 10px !important;
    margin-bottom: 10px;
    margin: 10px 15px;
  }
  .subtitle {
    font-size: 10px;
  }
  .text {
    height: auto;
    padding: 10px 5px;
    box-shadow: none;
    margin: 0;
  }

  .text_wrapper {
    padding: 0px;
  }

  .btn {
    font-size: 10px;
    height: 25px;
    padding: 8px 12px;
    margin: 0;
  }

  .border {
    display: none;
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  color: gray;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 90%;
  margin: 0 auto;
  min-height: 30vh;
  margin-bottom: 50px;
  align-items: center;
}

.partnerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
}

.icon {
  width: 200px;
  height: 70px;
  transition: all 0.3s ease-in;

  &:hover {
    cursor: pointer;
    scale: 1.06;
  }
}

.h2 {
  @include h2();

  margin: 150px auto !important;
  width: 85%;
  margin-bottom: 50px !important;
  font-size: 24px;
}

@media screen and (max-width: 500px) {
  .h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 950px) {
  .content {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .icon {
    width: 120px;
    height: 45px;
    transition: all 0.3s ease-in;
    margin: 10px 0;
  }
}

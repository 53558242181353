.projects {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  color: gray;
  // background-image: url("../../../public/test_star.png");
  // background-size: 250px 360px;
  position: relative;
  flex-direction: column;
  margin-top: 140px;
}

.top {
  clip-path: polygon(100% 0, 0% 100%, 0 0);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.icon {
  opacity: 0.3;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-top: 10px;
}

.favicon {
  width: 60px;
  height: 60px;
  margin-bottom: 40px;
  float: left;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -80px;
  margin-left: -60px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 10000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 10000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 10000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.text {
  margin-top: 50px;
}

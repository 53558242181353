@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.site {
  color: #69b1ff;

  &:hover {
    color: $gold;
  }
}

.contactsItem {
  display: grid;
  grid-template-columns: 60px 1fr;
}

.contactTitle {
  color: gray;
}

.consultantName {
  vertical-align: top !important;
}

.table {
  width: 100%;
}

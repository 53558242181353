@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.ant-steps-item-title {
  font-weight: 400 !important;
  margin: 0 !important;
  font-size: 15px !important;
}

.ant-steps-icon-dot,
.ant-steps-icon-tail::after {
  background: $gradient !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background: $gradient !important;
}
.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 0;
}

.introduction {
  color: gray;
  font-size: 15px !important;
  margin: 20px 0px !important;
}

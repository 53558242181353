@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.partners {
  width: 90%;
  margin: 50px auto;
  padding: 10px 0;

  div {
    margin: 50px 0;

    .img {
      width: 300px;
      height: 60px;
      object-fit: contain;
    }
  }
}

.h2 {
  @include h2();
  text-align: center;
  margin: 50px auto !important;
}

.slide:hover {
  cursor: pointer;
}

.marquee {
  height: 100px;
}

@media screen and (max-width: 500px) {
  .h2 {
    font-size: 20px;
  }

  .partners {
    margin: 0;
    div {
      margin: 0;
    }
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.chat {
  display: grid;
  grid-template-columns: 1fr 4fr;
  background: $base;
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  height: auto;
}

@media screen and (max-width: 800px) {
  .chat {
    grid-template-columns: 1fr;
    position: relative;
  }

  .content {
    width: 100vw !important;
    margin-top: -10px !important;
  }

  .messages {
    padding: 0 !important;
  }

  .personalInfo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
    width: 100%;
    height: 30px;
    border-radius: 0 !important;
  }

  .sidebar {
    width: 100%;
    height: 30px;
    padding: 5px 10px;
    border-bottom: 1px solid white;
    background: white;
    position: fixed;
    top: 70px;
    left: 0;
  }

  .mobile_header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img_wrapper {
    margin: 0 10px !important;
  }

  .img_wrapper,
  .img {
    width: 30px !important;
    height: 30px !important;
  }

  .addMessage {
    margin: 10px auto;
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 10px;
  }

  .back {
    font-size: 10px !important;
    color: gray;
    width: 80px;
  }
}

.h2 {
  @include h4();
  margin-top: 120px;
}

.sidebar {
  border-right: 1px solid $text-gold;
  background: white;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  scrollbar-color: silver $text-gold;
  scrollbar-width: thin;
}

.content {
  width: 100%;
  margin: 0px auto;
  background: $base;
  position: relative;
  height: auto;
}

.admin_message {
  background: $text-gold;
  font-size: 13px;
  line-height: 1;
  width: auto;
  padding: 0 20px !important;
  margin: 5px;
  border-radius: 15px;

  p {
    padding: 0 !important;
    line-height: 1 !important;
  }
}

.user_message {
  background: rgb(226, 226, 226);
  font-size: 13px;
  line-height: 1;
  width: auto;
  padding: 0px 20px;
  margin: 5px;
  border-radius: 15px;
}

.user,
.admin {
  display: flex;
  margin: 5px;
  position: relative;
}

.user {
  justify-content: flex-end;
  height: auto;
  display: grid;
  grid-template-rows: 1fr 0.3fr;

  .time {
    font-size: 8px;
    text-align: right;
    margin-right: 15px;
    margin-top: -3px;
    color: gray;
  }
}

.admin {
  display: grid;
  grid-template-rows: 1fr 0.3fr;
  width: auto;
  justify-content: flex-start;

  .time {
    font-size: 8px;
    text-align: right;
    margin-right: 15px;
    margin-top: -3px;
    color: gray;
  }
}

.img_wrapper {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  background: rgb(243, 241, 241);
  border-radius: 50px;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: contain;
  }
}

.personalInfo {
  border-radius: 20px;
  width: 90%;
  margin: 20px auto;
  text-align: center;
  font-size: 14px;
}

.addMessage {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 6fr 0.5fr;
  background: whitesmoke !important;
  padding: 25px 0;
  padding-left: 20px;
}

.btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  border-radius: 50%;
  margin-top: 20px;
  height: 40px;
  width: 40px;
  border: none;
  background: $text-gold;
}

.send_icon {
  color: white;
}

.btns {
  display: flex;
  justify-content: center;
}

.link_btn {
  @include btn($text-gold);
  border-radius: 20px;
  margin-top: 20px;
  height: 15px;
  width: 60%;
}

.dateWrapper {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}

.date {
  font-size: 12px;
  padding: 0px 15px;
  border-radius: 20px;
  background: $text-gold;
  opacity: 0.6;
  color: white;
}

.messages {
  height: calc(100vh - 250px - 20px);
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: white !important;
  padding: 0 30px;
  width: 95%;
  margin: 0 auto;
}

.user_message {
  padding: 0 20px;

  p {
    padding: 0 !important;
    line-height: 1 !important;
  }
}
.messages_head {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  width: 100%;
}
.messages_header {
  height: 40px;
  width: 85%;
  padding: 5px 20px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  font-weight: 700;
  height: 22px;
}

.sender_name {
  font-weight: 600;
  font-size: 13px;
  height: 22px;
}

.email {
  color: gray;
  font-size: 12px;
  height: 20px;
}

// .last_message {
//   color: gray;
//   font-size: 11px;
//   height: 20px;
//   display: flex;
//   justify-content: space-between !important;
//   align-items: center;
//   width: 100%;
// }

// .last_message_date {
//   font-size: 9px;
// }

// .sidebar_sender {
//   padding: 5px 10px;
//   border-bottom: 1.5px solid $base;
//   width: 92%;
//   display: grid;
//   grid-template-columns: 40px 1fr;
//   align-items: center;
//   gap: 10px;
//   &:hover {
//     background: $base;
//   }
// }

.avatar_wrapper {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  background: $base;
  margin-left: 5px;
  margin-right: 10px;
}

// .message {
//   width: 125px;
//   height: 20px;
//   overflow: hidden;
// }

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.back {
  font-size: 12px;
  color: gray;
}

.icon {
  color: $text-gold;
}

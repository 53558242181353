@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  background: white;
  border-radius: 20px;
  width: 100%;
  margin: 20px auto;
  padding: 10px 40px;
}
.accordion_header {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.email {
}

.icon {
  color: $text-gold;
  margin-left: 10px;
}

.img_wrapper {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background: rgb(243, 241, 241);
  border-radius: 50px;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: contain;
  }
}

.content_item {
  font-size: 12px;
  margin: 10px auto;
  background: repeating-radial-gradient();
  align-items: center;
  width: 85%;
  display: grid;
  grid-template-columns: 1.1fr 2.5fr;
}

.content_item_title {
  color: gray;
  display: flex;
  height: 100%;
  align-items: flex-start;
  font-size: 11px;
}

.content_item_value {
  display: flex;
  height: 100%;
  align-items: flex-start;
  line-height: 1.5;
}

.acc_btn {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border: none !important;
  background: white !important;
  margin: 10px 0;
  border-radius: 20px;
  width: 90vw !important;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $base;
}

.panel {
  border-radius: 20px;
  background: white;
  padding: 20px 0;
}

.btn {
  @include btn($text-gold);
  width: 170px;
  margin: 20px auto;
  height: 15px;
  border-radius: 20px;
  margin-top: 40px;
  font-size: 10px;
}

.edit_icon {
  margin-right: 5px;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.reports {
  width: 80%;
  margin: 80px auto;
  min-height: 300px;
  margin-top: 180px;
}

.h2 {
  @include h2();
  margin: 70px auto;
}

.subtitle {
  letter-spacing: 0.5px;
  line-height: 1.8;
}

.documents_item {
  padding: 25px 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  justify-content: space-between;
  align-items: center;

  border-bottom: 0.5px solid $gold;
}

.documents_item:last-child {
  border-bottom: none !important;
}

.icon {
  color: $gold;
}

.documents {
  margin: 50px auto;
}

.title {
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  width: 90%;
  text-align: left;
  border: none;
  background: none;

  &:hover {
    font-weight: 600px;
    color: gray;
    cursor: pointer;
  }
}

.btn {
  @include btn($gold);
  height: 15px;
  width: 50px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 650px) {
  .h2 {
    line-height: 1.6;
    margin: 20px 0;
    font-size: 20px;
  }

  .documents_item {
    display: grid;
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 12px;
    width: 100%;
    margin: 0;
    font-weight: 500;
  }

  .subtitle {
    font-size: 14px;
    line-height: 1.8;
    color: gray;
    margin-top: 10px;
  }

  .btn {
    margin: 20px 0;
  }
  .reports {
    margin-top: 150px;
  }
}

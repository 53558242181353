@import "../../styles/varibles.scss";

.header {
  color: $red;
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: $red;
  align-content: center;
}

.logo {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 50px;
  height: 80px;

  div {
    text-align: flex-start;
    line-height: 1.6;
    font-size: 11px;
    letter-spacing: 1px;
  }
}

.menu_wrapper {
}

.logo_img {
  width: 70px;
  height: 40px;
  margin-right: 15px;
}

.logo_img_mobile {
  width: 70px;
  height: 30px;
  margin-right: 15px;
}

.menu {
  display: flex;
  font-weight: 400;
  justify-content: flex-end;
  margin-right: 30px;
}

.menu {
  ol {
    padding: 8px 0;
    margin: 10px 20px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in;
    //text-shadow: 1px 1px 0.5px rgba(125, 124, 124, 0.28);
    font-weight: 600;
    // color: whitesmoke;
    // color: $red;
    color: $text-color;

    &:hover {
      // font-weight: 500;
      color: $text-gold;
      cursor: pointer;
      // text-shadow: 0.5px 0.5px 0.5px rgba(0,0,0,0.28);
    }
  }
}

.active {
  font-weight: 600;
  color: $dark-blue !important;
  // border-bottom: 2px solid $red;
}

@media screen and (max-width: 1048px) {
  .logo_text {
    font-size: 9px !important;
  }

  ol {
    font-size: 14px;
  }
}

.logo_image {
  width: 70px;
  height: 40px;
  object-fit: cover;
}

.header_logo {
  width: 180px;
  height: 55px;
  margin-top: 10px;
}

.logo_link {
  height: 100%;
  display: flex;
  align-items: center !important;
}

.icon {
  margin-right: 8px;
  font-weight: 200;
  font-size: 14px;
  color: $gold;
}

.img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.img_wrapper {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}

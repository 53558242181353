@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.calculator {
  background: $base;
  padding: 50px 0;
}

.h2 {
  @include h2();
  text-align: center;
  line-height: 1.5;
  margin: 50px auto;
}

.content {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  gap: 1em;
  padding: 30px 0;
}

@media screen and (max-width: 1100px) {
  .result_title {
    font-size: 12px !important;
  }

  .result_value {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 990px) and (min-width: 520px) {
  .content {
    grid-template-columns: 1fr;
  }

  .result_title {
    font-size: 12px !important;
  }

  .result_value {
    font-size: 25px !important;
  }
  .drawer {
    margin-top: 70px;
  }
}

@media screen and (max-width: 520px) {
  .content {
    grid-template-columns: 1fr;
  }

  .result_title {
    font-size: 11px !important;
  }

  .result_value {
    font-size: 20px !important;
  }

  .form {
    grid-template-columns: 1fr !important;
  }

  .schedule_btn {
    font-size: 10px !important;
  }
  .drawer {
    margin-top: 70px;
  }

  .total {
    font-size: 12px;
  }

  .table {
    margin-bottom: 120px !important;
    height: auto !important;
  }

  .totalRow {
    margin-bottom: 50px;
  }
}

.form {
  width: 80%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  justify-content: center;
}

.input {
  padding: 10px;
  border: none;
  background: white;
  width: 90%;
}

.credit_results {
  background: $red;
  width: 65%;
  margin: 0 auto;
  padding: 30px 50px;
}

.result_title {
  font-size: 14px;
  font-weight: 500;
  color: white;
  letter-spacing: 1px;
  margin-bottom: -5px !important;
}

.result_value {
  font-size: 30px;
  font-weight: 600;
  color: white;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.select {
  border-radius: none !important;
}

.btn {
  @include btn($gold);
}

.schedule_btn {
  @include btn($gold);
  font-size: 13px;
  color: white;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 10px !important;
}

.table_header {
  margin: 20px 5px;
}

.table_header_item {
  display: flex;
}

.table_header_value {
  font-weight: 600;
}

.calculatorSelect {
  border-radius: 0 !important;
  outline: none !important;
  option {
    height: 30px !important;
    outline: none !important;
  }
}

.table {
  margin-bottom: 30px !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: white !important;
    color: $text-gold !important;
  }
}

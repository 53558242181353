@import '../../styles/varibles.scss';
@import '../../styles/mixins.scss';


.projects{
	background: $base;
	width: 100%;
	height: 1000px;
	padding: 50px 0;
}

.h2{
	@include h2();
	margin: 40px auto !important;
	text-align: center;
}

.content{
	display: grid;
	grid-template-columns: 1fr 1fr ;
	gap: 3em;
	width: 90%;
	justify-content: space-between;
	margin: 40px auto;
}

.project_item{
	width: 100%;
	height: 330px;
	background: white;
	-webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
	box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
	transition: all 0.3s ease-in;
	border: 1px solid $light-blue;
	padding: 40px auto;
	//  border-radius: 5px;


	&:hover{
		scale: 1.03;
		 border: 1px solid $green;

		 .icon{
			color: $green;
		}
	}
}

.title{
	margin: 20px;
	font-size: 15px;
	text-align: center;
	font-weight: 600;
	letter-spacing: 0.5px;
}

.subtitle{
	// color: gray;
	font-size: 13px;
	width: 85%;
	margin: 10px auto;
	line-height: 1.5;
	letter-spacing: 0.5px;
}

.icon{
	font-size: 30px;
	color: $light-blue;
	transition: all 0.3s ease-in;
}

.icons_wrapper{
	margin: 20px auto;
	display: flex;
	justify-content: center;
}

.description{
	width: 80%;
	margin: 20px auto;
	color: gray;
	line-height: 1.8;
	text-align: center;
	letter-spacing: 0.5px;
	font-size: 14px;
}

.credit{
	font-size: 13px;
	line-height: 1.8;
	width: 82%;
	margin: 10px auto;
	color: gray;
}


@media screen and (max-width: 1180px){
	.title{
		font-size: 13px !important;
	}

	.subtitle{
		font-size: 12px !important;
	}
	.credit{
		font-size: 12px !important;

	}
}

@media screen and (max-width: 1050px){

	.projects{
		height: auto;
	}
	.content{
   
	grid-template-columns: 1fr ;
	
}
.project_item{
	height: auto;
	width: 80%;
	padding: 20px;
	margin: 10px auto;
}
}

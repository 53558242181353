@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.faq {
  width: 100%;
  margin: 20px auto;
  -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
  background: $base;
}

.h2 {
  @include h2();
  text-align: center;
  line-height: 1.5;
}

.subtitle {
  text-align: center;
  color: gray;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.wrapper {
  padding: 50px;
}

.accordion {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px;
  gap: 1em;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 900px) {
  .accordion {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .wrapper {
    padding: 0;
    padding-top: 50px;
  }

  .subtitle,
  .h2 {
    width: 90%;
    margin: 0 auto;
  }

  .subtitle {
    margin-top: 30px;
  }
}

@media screen and (max-width: 500px) {
  .faq {
    width: 100%;
    height: 100%;
  }

  .h2 {
    font-size: 20px;
    margin-bottom: 20px;
    width: 85%;
    margin: 0 auto;
  }

  .subtitle {
    font-size: 12px;
    width: 85%;
    margin: 30px auto;
    margin-bottom: 20px;
  }

  .wrapper {
    width: 95%;
    margin: 10px auto;
    padding: 0;
    padding-top: 40px;
  }

  .accordion {
    margin: 10px auto;
  }

  .item {
    font-size: 12px;
  }

  .panel {
    font-size: 12px !important;
  }
}

.item {
  background: white;
  border-radius: 10px;
  margin: 5px;

  width: 100%;
}

.btn {
  background: white;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
}

.icon {
  color: $gold;
}

.heading {
  font-weight: 600;
  padding: 20px 30px;
}

.panel {
  color: gray;
  font-size: 14px;
  padding: 0px 30px 20px 30px;
  letter-spacing: 0.5px;
}
.question_icon {
  font-size: 18px;
  color: $gold;
}

.item {
  transition: all 0.3s ease-in;
  &:hover {
    transform: translateY(-5px);
    -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);

    .icon {
      color: $red;
    }
  }
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.h2 {
  @include h2();
  text-align: center;
  padding: 50px;
  line-height: 1.5;
}

@media screen and (max-width: 500px) {
  .h2 {
    font-size: 20px;
    padding: 20px;
  }
}

// .map {
//   // background: $base;
//   -webkit-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
//   -moz-box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
//   box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
// }

// iframe {
//   border: none !important;
//   outline: none !important;
// }

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.content {
  margin: 70px auto;
  width: 85%;
  margin-top: 150px;
}

.title {
  @include h2();
  margin: 30px auto;
  margin-bottom: 50px;
  line-height: 1.5;
}

.strong {
  font-weight: 600;
  margin: 5px 0;
  margin-top: 30px;
}

@media screen and (max-width: 750px) {
  .content {
    margin: 70px auto;
    width: 85%;
    margin-top: 80px;
  }
  .title {
    @include h2();
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 1.5;
    font-size: 20px;
  }
}

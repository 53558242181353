@import "../src/styles/varibles.scss";

* {
  font-family: Montserrat;
  letter-spacing: 0.5px;
  line-height: 2;
}

a {
  text-decoration: none;
  color: inherit;
}

.ant-drawer-title {
  text-align: center;
  font-weight: 700 !important;
  letter-spacing: 1px;
  margin: 10px auto;
}

.ant-table-cell,
.ant-table-thead {
  font-size: 14px !important;
  line-height: 1.3 !important;
}

@media screen and (max-width: 1200px) {
  .ant-table-cell {
    font-size: 12px !important;
    padding: 10px !important;
  }

  .ant-tabs .ant-tabs-tab-btn {
    font-size: 12px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $gold !important;
}

.react-multi-carousel-track {
  margin-left: 25px !important;
}

@media screen and (max-width: 650px) {
  .react-multi-carousel-track {
    margin-left: 0px !important;
  }

  .react-multi-carousel-item {
    display: flex !important;
    justify-content: center !important;
  }
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  box-shadow: 0 0 0 2px rgb(232 239 245 / 10%) !important;
}

.ant-input {
  border-radius: 10px !important;
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-form-item .ant-form-item-label > label {
  color: rgb(107, 106, 106);
}

.ant-drawer-header {
  border: none;
  border-bottom: none !important;
}

.ant-drawer .ant-drawer-body {
  padding: 10px 20px;
}

.ant-checkbox,
.ant-checkbox:hover,
.ant-checkbox:focus,
.ant-checkbox:focus-within,
.ant-checkbox:focus-visible,
.ant-checkbox-inner {
  border: 1px solid $gold !important;
  outline: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: $gold !important;
  border: 1px solid $gold !important;
  border-radius: 0 !important;
  outline: none !important;
}
@media screen and (max-width: 2500px) and (min-width: 600px) {
  .ant-tooltip,
  .ant-tooltip-content {
    height: auto !important;
    width: 413px !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 600px) {
  .ant-tooltip,
  .ant-tooltip-content {
    height: auto !important;
    width: 250px !important;
    font-size: 10px !important;
  }

  .appriser_table {
    .ant-table-cell {
      padding: 10px !important;
      font-size: 10px !important;
      line-height: 1.5 !important;
    }
  }
}

.ant-drawer,
.ant-drawer-mask,
.ant-drawer-content-wrapper {
  z-index: 5 !important;
}

.partner_table {
  .ant-table-cell {
    padding: 10px 15px !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 800px) {
  .partner_mobile_table {
    width: 90% !important;

    padding: 0 !important;
    margin: 0 auto !important;

    .ant-table-cell {
      padding: 10px 5px !important;
      font-size: 10px !important;
      line-height: 1.5 !important;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 400px) {
  .partner_mobile_table {
    margin-left: 5px !important;
  }
}

input:-internal-autofill-selected {
  background-color: none !important;
  transition: background-color 5000s ease-in-out 0s;
}
input[data-autocompleted] {
  background-color: white !important;
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 12px !important;
}

.ant-select-focused:where(
    .css-dev-only-do-not-override-1xg9z9n
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: none;
  box-shadow: none;
}

.ql-editor {
  color: black;
  height: auto;
  min-height: 20px;
}

.ql-editor .ql-blank {
  padding: 5px 10px !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 20px !important;
  background: white;
  border: 1px solid $text-gold;
  height: auto;
}

.ql-toolbar.ql-snow {
  border: 1px solid $text-gold !important;
  background: $text-gold;
  padding: 0 8px !important;
}

.ql-picker-label,
.ql-snow,
.ql-picker.ql-expanded,
.ql-stroke {
  color: white !important;
  stroke: white !important;
  stroke-width: 1.5 !important;
  transition: all 0.1s ease-in;
  &:hover {
    color: $red !important;
  }
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.accordion__button {
  color: $text-gold !important;
}

.accordion {
  border: none !important;
}

.ant-drawer {
  margin-top: 70px !important;
}

@import "../../styles/varibles.scss";
@import "../../styles/mixins.scss";

.sidebar {
  width: 270px;
  // height: 30px;
  height: 100vh;
  padding: 5px 10px;
  border-bottom: 1px solid white;
  background: white;
  position: fixed;
  top: 70px;
  left: 0;
  overflow-y: scroll;
  scrollbar-color: silver;
  scrollbar-width: thin;
}

.last_message {
  color: gray;
  font-size: 11px;
  height: 20px;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}

.last_message_date {
  font-size: 9px;
}

.sidebar_sender {
  padding: 5px;
  border-bottom: 1.5px solid $base;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 10px;
  overflow: hidden;
  height: 55px;
  align-items: center;
  align-content: center;

  &:hover {
    background: $base;
  }
}

.sender_name {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1.5;
  display: flex;
  align-content: center;

  &:hover {
    background: $base;
  }
}

.avatar_wrapper {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  background: $base;
  margin-left: 5px;
  margin-right: 10px;
}

.message {
  width: 125px;
  height: 20px;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
@media screen and (max-width: 800px) {
  .sidebar {
    height: 100vh;
    padding-top: 0px;
    overflow-y: scroll;

    width: 95%;
  }

  .sidebar_sender {
    margin: 0 auto;
  }

  .message {
    width: 70%;
  }
}
.icon {
  color: $text-gold;
  margin-right: 5px;
}

.back {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 10px;
  height: 45px;
  border-bottom: 0.5px solid $text-gold;
}

.users {
  font-weight: 600;
}

.img_wrapper {
  width: 60px;
  height: 60px;
  margin: 20px auto;
  background: rgb(243, 241, 241);
  border-radius: 50px;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: contain;
  }
}

.link_btn {
  @include btn($text-gold);
  width: 70%;
  margin: 5px auto;
  border-radius: 20px;
  height: 35px;
  padding: 0px 10px;
  margin: 20px auto;
}

.content_item {
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}
